<template>
<!--  <div class="out-box" v-loading.body="loading">-->
  <div class="develop-container">
    <h2>Publish Device Config MQTT MESSAGE</h2>
    <!--
    <div class="my-key">TenantId</div>
    <div class="input-box">
      &lt;!&ndash;      <input-text :title="'TenantId'" @change="tenantId = $event" ref="inputTenantId" :placeholder="''"></input-text>&ndash;&gt;
      <el-select v-model="tenantId" placeholder="请选择">
        <el-option
          v-for="item in tenants"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      -->
    <input-text :title="'SerialNumber'" v-model="sn" :placeholder="''"></input-text>
    <div>
      <button type="button" @click="clickForGet" :disabled="disableBtn">获取Config</button>
      <button type="button" @click="clickForReset" :disabled="disableBtn">恢复到修改前</button>
      <button type="button" @click="clickForUpdate" :disabled="disableBtn">更新Config</button>
    </div>
    <div>
      <textarea class="json-box" v-model="text"></textarea>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText'
import request from '@/utils/request'
import axios from 'axios'
import Message from "@arco-design/web-vue/es/message";

export default {
  components: {
    InputText
  },
  mounted() {
    // this.$refs.inputTenantId.setValue(this.tenantId);
    // this.$refs.inputSn.setValue(this.sn);

    // if (this.deviceConfig) {
    //   this.text = this.toJson(this.deviceConfig);
    // }
  },
  data() {
    return {
      tenants: [
        {label: "paas_owned", value: "paas_owned"},
        {label: "dzees", value: "dzees"},
        {label: "guard", value: "guard"},
        {label: "jxja", value: "jxja"},
        {label: "rock", value: "rock"},
        {label: "soliom", value: "soliom"},
        {label: "vicoo", value: "vicoo"},
      ],

      accountId: 'paas_owned',
      tenantId: 'paas_owned',
      // sn: '6e068b3cea4d3f11a35ffca5bc8badd2',
      sn: '',
      // configId: 'Y8Wp8vHZkaozRAH2cKcst4',
      // configId: null,
      // sn: 'f4d4fd45b8713638965dc5b3a10bb35a',
      // configId: '',
      // tenantId: 'netvue',
      deviceConfig: {'a': 1, 'b': ['1', '2']},
      text: null,
      loading: false
    }
  },
  computed: {
    disableBtn() {
      return !(this.accountId && this.accountId.trim() != ''
        && this.tenantId && this.tenantId.trim() != ''
        && this.sn && this.sn.trim() != '');
    }
  },
  watch: {
    deviceConfig() {
      this.clickForReset();
    }
  },
  methods: {
    toJson(obj) {
      return JSON.stringify(obj, null, 4)
    },
    async clickForGet() {
      if (this.disableBtn) {
        return;
      }
      this.loading = true;
      /*
      try {
        if (!this.configId) {
          const createResp = await this.createDeviceConfig(this.accountId, this.tenantId, this.sn.trim(), {})
          console.log('createResp', createResp)
          if (createResp.code == 301) {
            this.configId = createResp.data.configId;
          }
        }
        const updateResp = await this.updateDeviceConfig(this.accountId, this.tenantId, this.sn.trim(), this.configId, {})
        console.log('clickForGet updateDeviceConfig', updateResp)
        if (updateResp.code == 0) {
          this.deviceConfig = updateResp.data.content;
        } else {
          this.deviceConfig = null;
        }
      } finally {
        this.loading = false;
      }
      */
      try {
        const resp = await this.createPaasOwnedDeviceConfig(this.accountId, this.tenantId, this.sn.trim());
        if (resp.code == 0) {
          this.deviceConfig = resp.data;
        } else {
          this.deviceConfig = null;
        }
      } catch (e) {
        Message.error(JSON.stringify(e));
      } finally {
        this.loading = false;
      }
    },
    clickForReset() {
      this.text = this.deviceConfig ? this.toJson(this.deviceConfig) : '';
    },
    async clickForUpdate() {
      if (this.disableBtn || !this.text || this.text.trim() == '') {
        Message.error('config is empty!');
        return;
      }
      this.loading = true;
      try {
        // const resp = await this.createDeviceConfig(this.accountId,this.tenantId, this.sn, {})
        const newDeviceConfig = JSON.parse(this.text);
        const configId = (newDeviceConfig.configId || '').trim();
        if (configId == '') {
          Message.error('configId is empty!');
          return;
        }
        const updateResp = await this.updateDeviceConfig(this.accountId, this.tenantId, this.sn.trim(), configId, newDeviceConfig)
        console.log('clickForUpdate updateDeviceConfig', updateResp)
        if (updateResp.code == 0) {
          this.deviceConfig = updateResp.data.content;
        } else {
          this.deviceConfig = null;
        }
      } catch (e) {
        Message.error(JSON.stringify(e));
      } finally {
        this.loading = false;
      }
    },
    async createPaasOwnedDeviceConfig(accountId, tenantId, sn) {
      const requestConfig = {
        url: '/aiDemo/invokePaasApi',
        method: 'post',
        params: {
          accountId: accountId,
          tenantId: tenantId,
          nodeName: 'staging-cn',
          path: `/open-api/createPaasOwnedDeviceConfig/${sn}`,
          method: 'POST'
        },
        data: {}
      }
      return await request(requestConfig)
    },
    async updateDeviceConfig(accountId, tenantId, sn, configId, configData) {
      const requestConfig = {
        url: '/aiDemo/invokePaasApi',
        method: 'post',
        params: {
          accountId: accountId,
          tenantId: tenantId,
          nodeName: 'staging-cn',
          path: `/open-api/tenants/${tenantId}/devices/${sn}/configs/${configId}`,
          method: 'PUT'
        },
        data: configData
      }
      return await request(requestConfig)
    },
    async createDeviceConfig(accountId, tenantId, sn, configData) {
      const requestConfig = {
        url: '/aiDemo/invokePaasApi',
        method: 'post',
        params: {
          accountId: accountId,
          tenantId: tenantId,
          saasNode: 'staging-cn',
          path: `/open-api/tenants/${tenantId}/devices/${sn}/configs`,
          method: 'POST'
        },
        data: configData,
        baseURL: process.env.VUE_APP_BASE_API,
        headers: {'Authorization': this.$store.getters.token},
        timeout: 30 * 1000
      }
      const httpResp = await axios.request(requestConfig);
      return httpResp.data
    }
  }
}
</script>

<style type="scss" scoped>
.out-box {
  padding-left: 14px;
  padding-right: 14px;
}

.my-key {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;

  /*// padding: 32 px 0 8 px 0;*/
  margin-bottom: 8px;
}

.json-box {
  width: 800px;
  height: 600px;
  padding: 10px;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

button {
  margin: 0 15px 10px 0;
}
</style>
